/* Formulário de criação de pedidos */

.metade {
	max-width: 70%;
}


.group-filds {
	border: 1px solid #e7e7e7;
	padding: 20px;
	margin-bottom: 10px;
}

.form-group {
	margin-bottom: 5px;
}

.transportadoras {
	margin-top: 10px;
}


/* Página de adição de itens de pedido */

.busca-produtos {
	background-color: #f8f8f8;
	border: 1px solid #e7e7e7;
	margin-top: 0;
	min-height: 50px;
	padding-top: 8px;
}

.sem-margem {
	margin-bottom: 0;
}

.footer-additens {
	position:absolute;
	bottom:0;
	width:100%;
	background-color: #f8f8f8;
	border-top: 1px solid #e7e7e7;
	height: 50px;
}

.busca-produtos input {
	border: 1px solid #aaa;
    border-radius: 4px;
    height: 28px;
    color: #555;
    width: 90%;
    margin-left: 10%;
}

.busca-produtos .valor-caixa {
	width: 88%;
	margin-left: 2%;
}

.busca-produtos .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #b2b2b2;
}

.busca-produtos .select2-container {
	margin-left: 5%;
}

.busca-produtos .form-group {
	padding-left: 0;
	padding-right: 0;
}

.busca-produtos .btn {
    width: 90%;
    margin-left: 5%;
    height: 28px;
    padding-top: 3px;	
}

/* Adição itens no pedido rodapé */

.footer-container {
	text-align: right;

}

.footer-status, .footer-btn {
	width: 200px;
	float: right;
}

.footer-btn {
	width: 120px;
	padding-top: 8px;
}

.footer-status p, .footer-btn p {
	width: 200px;
	margin-bottom: 4px;
	font-size: 11px;
	padding-top: 4px;
}

/* Condicação de pagamento */
.condpg-principal {
	text-align: center;
}


.has-success {
	color: green;
}


.has-error {
	color: red;
}

.busca-produtos input[type="checkbox"] {
	width: 20px;
	margin-top: 0px;
	margin-left: 33px;
	float: left;
}

.busca-produtos .radio-inline span {
  display: inline-block;
  margin-top: 4px;
}

.busca-produtos input[type="radio"] {
  margin-top: 0;
}

.busca-produtos .panel {
  margin: 2px;
}

.busca-produtos .panel-body {
  padding: 4px;
}

.provisorio-label {
	float: left;
	margin-top: 4px;
	font-weight: 500;
}

.navbar-brand {
	padding: 0;
}

/* diversas */

.esconder {
	display: none;
}

.padding-zero {
	padding: 0;
}


/* relatorio de pedidos */

#gerar-pedidos, #gerar-pdf-pedidos {
  width: 48%;
  margin-left: 0;
}

.label-ordem {
	padding-top: 5px;
}


@media (min-width: 768px) {
	.label-ordem {
		font-size: 9px;
		padding-top: 5px;
	}

}

/* liberar produtos */
.padding-top-30 {
	padding-top: 30px;
}

